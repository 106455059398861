import React from "react";
import { Layout } from "../../components/common/layout";
import { Banner } from "../../components/common/banner";
import { Intent } from "../../components/common/intent";
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-controle-de-expedicao.jpg";

const ExpedicaoPage = () => (
        <Layout infoPage={{page: "Controle de Expedição"}}>
            <main className="solucoes-subpage">
                <Banner backgroundImage={backgroundIMG}>
                    <h1 className="title bold">CONTROLE<br/><span className="bold" style={{ color: "#FFF" }}>DE EXPEDIÇÃO</span></h1>
                    <div className="gray-box">
                        <span className="text">Com a utilização de portais eletrônicos utilizando Tecnologia RFID é possível saber de fato o que realmente embarcou no veículo.</span>
                    </div>
                </Banner>
                <section className="content">
                    <p className="text"><span className="bold" style={{ fontSize: "1.5rem", fontStyle: "italic" }}>Com o Sistema SOWX</span> Integrado com o corporativo e através do conjunto de notas fiscais que devem ser embarcados, o Sistema SOWX orienta os operadores a embarcar somente o que está relacionado com nota fiscal</p>
                    <p className="text">As divergências são informadas em um monitor na área de expedição.</p>
                    <Intent classes="button" to="/#solutions">
                        <span className="medium">DEMAIS SOLUÇÕES</span>
                    </Intent>
                </section>
            </main>
        </Layout>
    )

export default ExpedicaoPage;